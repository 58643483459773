import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const EventPaper = styled(Paper)(({ theme }) => ({
  width: "300px",
  height: "100px",
  borderRadius: "20px",
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  color: "#D4AF37",
  marginBottom: theme.spacing(2), // Agregamos margen inferior
  display: "flex",
  alignItems: "center", // Centra el contenido verticalmente
  justifyContent: "center", // Centra el contenido horizontalmente
}));

export default function EventCard({ titulo, titulo2 }) {
  return (
    <div>
      <Stack direction="column" spacing={3}>
        <EventPaper square={false}>
          <h3>{titulo}</h3>
        </EventPaper>
        <EventPaper square={false}>
          <h3>{titulo2}</h3>
        </EventPaper>
      </Stack>
    </div>
  );
}
