import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ModalCrt from "./ModalCrt/ModalCrt";
import crt1 from "../../assets/Certificados/Titulo de COP H.png";
import crt2 from "../../assets/Certificados/MASTER IANLP H.jpg";
import crt3 from "../../assets/Certificados/MASTER HIPNOTIC CAH.jpg";
import "./Bio.css";

const CustomAccordion = styled(Accordion)({
  backgroundColor: "black",
});

const CustomAccordionSummary = styled(AccordionSummary)({
  color: "#D4AF37",
});

const CustomAccordionDetails = styled(AccordionDetails)({
  color: "#D4AF37",
});

export default function Bio() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="container">
      <CustomAccordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{
          marginBottom: 3,
          borderRadius: 5,
          overflow: "hidden",
        }}
      >
        <CustomAccordionSummary
          expandIcon={
            <ArrowDropDownIcon style={{ color: "#D4AF37" }} ExpandIconDetails />
          }
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>
            <h3>¿Quién soy?</h3>
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography>
            <p className="text-bio">
              Soy un profesional comprometido en asistir y acompañar a las
              personas, empresas y organizaciones para que vivan una vida
              inspirada y con propósito.
            </p>
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        sx={{
          marginBottom: 3,
          borderRadius: 5,
          overflow: "hidden",
        }}
      >
        <CustomAccordionSummary
          expandIcon={<ArrowDropDownIcon style={{ color: "#D4AF37" }} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>
            <h3>Mi historia</h3>
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography>
            <p className="text-bio">
              Durante 36 años, fui violinista en la Orquesta del Teatro
              Argentino de La Plata, Buenos Aires, Argentina. Desde una edad
              temprana, comprendí el valor de la disciplina, enfrenté desafíos y
              frustraciones, y competí por alcanzar mis sueños. Al descubrir el
              coaching y la Programación Neurolingüística (PNL), entendí cómo
              mis interpretaciones de la realidad, mis patrones mentales y mis
              emociones condicionaban mis resultados, relaciones y mi vida en
              general. Al identificar y modificar estas limitaciones, descubrí
              nuevas oportunidades y posibilidades para alcanzar mis objetivos.
              Implementando estas herramientas tanto en mi vida personal como en
              la de mis alumnos de violín, y en la asistencia a personas en sus
              vidas personales y profesionales, confirmé que el cambio es
              posible. Hoy me dedico a acompañar a personas, empresas y
              organizaciones en el logro de sus objetivos, mientras disfrutan
              del proceso de caminar hacia el éxito.
            </p>
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        sx={{
          marginBottom: 3,
          borderRadius: 5,
          overflow: "hidden",
        }}
      >
        <CustomAccordionSummary
          expandIcon={<ArrowDropDownIcon style={{ color: "#D4AF37" }} />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography>
            <h3>Frases que me identifican</h3>
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography>
            <p className="text-bio">
              "Cuida tus pensamientos, porque se convertirán en tus palabras.
              Cuida tus palabras, porque se convertirán en tus actos. Cuida tus
              actos, porque se convertirán en tus hábitos. Cuida tus hábitos,
              porque se convertirán en tu destino." - Gandhi
            </p>
            <p className="text-bio">
              "Comienza haciendo lo necesario, luego lo posible y terminarás
              haciendo lo imposible." - San Francisco de Asís
            </p>
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        sx={{
          marginBottom: 3,
          borderRadius: 5,
          overflow: "hidden",
        }}
      >
        <CustomAccordionSummary
          expandIcon={<ArrowDropDownIcon style={{ color: "#D4AF37" }} />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          <Typography>
            <h3>Mi misión</h3>
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography>
            <p className="text-bio">
              Mi misión es facilitar procesos de transformaciones profundas y
              duraderas para personas, empresas y organizaciones de todo el
              mundo. Mi objetivo es maximizar su potencial, logrando resultados
              extraordinarios y viviendo vidas plenas y significativas. Enseño
              principios universales y estrategias efectivas, cultivo el
              liderazgo, la integridad y la excelencia personal y profesional
              para que puedan alcanzar sus metas y sueños.
            </p>
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
        sx={{
          marginBottom: 3,
          borderRadius: 5,
          overflow: "hidden",
        }}
      >
        <CustomAccordionSummary
          expandIcon={<ArrowDropDownIcon style={{ color: "#D4AF37" }} />}
          aria-controls="panel5-content"
          id="panel5-header"
        >
          <Typography>
            <h3>Mi visión</h3>
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography>
            <p className="text-bio">
              "Crear una comunidad global de individuos y organizaciones que
              operen con confianza, integridad y eficacia. Aspiro a ser
              reconocido como un líder internacional en el desarrollo personal,
              organizacional y empresarial, transformando vidas e inspirando a
              millones a vivir vidas auténticas, plenas y extraordinarias,
              liderando un movimiento de cambio positivo que permita a las
              personas alcanzar sus sueños y mejorar el mundo que nos rodea.
              Visiono habitar un mundo más consciente, compasivo y exitoso, en
              donde cada persona tenga las herramientas para cambiar su vida,
              inspirar a otros a hacer lo mismo y generar un impacto positivo y
              duradero en la sociedad."
            </p>
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
        sx={{
          marginBottom: 3,
          borderRadius: 5,
          overflow: "hidden",
        }}
      >
        <CustomAccordionSummary
          expandIcon={<ArrowDropDownIcon style={{ color: "#D4AF37" }} />}
          aria-controls="panel6-content"
          id="panel6-header"
        >
          <Typography>
            <h3>Formación académica</h3>
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography>
            <ul>
              <li className="list text-bio">
                <ModalCrt imgCrt={crt1}>
                  ELAC - Escuela Latinoamericana de Coaching Coach Ontológico
                  Profesional (aval internacional FICOP - AACOP)
                </ModalCrt>
              </li>
              <br />

              <li className="list text-bio">
                <ModalCrt imgCrt={crt2}>
                  INSTITUTO ELBA SELDES Master Trainer en PNL (Programación
                  Neurolingüística) (aval internacional IANLP)
                </ModalCrt>
              </li>
              <br />
              <ModalCrt imgCrt={crt3}>
                <li className="list text-bio">
                  CENTRO ARGENTINO DE HIPNOSIS Master Hipnotic CAH
                </li>
              </ModalCrt>
            </ul>
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>
    </div>
  );
}
