import img03 from "../../assets/imgExp/img03.jpeg";
import img05 from "../../assets/imgExp/img05.jpeg";
import img06 from "../../assets/imgExp/img06.jpeg";
import img07 from "../../assets/imgExp/img07.jpeg";
import img14 from "../../assets/imgExp/img14.jpeg";
import img21 from "../../assets/imgExp/img21.JPG";
import img26 from "../../assets/imgExp/img26.JPG";

export const cardExpData = [
  {
    ImgSrc: img03,
    CrdTitle: "Titulo de la Experiencia",
    CrdText: "Texto descriptivo de la Experiencia",
  },
  {
    ImgSrc: img05,
    CrdTitle: "Titulo de la Experiencia",
    CrdText: "Texto descriptivo de la Experiencia",
  },
  {
    ImgSrc: img06,
    CrdTitle: "Titulo de la Experiencia",
    CrdText: "Texto descriptivo de la Experiencia",
  },
  {
    ImgSrc: img07,
    CrdTitle: "Titulo de la Experiencia",
    CrdText: "Texto descriptivo de la Experiencia",
  },
  {
    ImgSrc: img14,
    CrdTitle: "Titulo de la Experiencia",
    CrdText: "Texto descriptovo de la Experiencia",
  },
  {
    ImgSrc: img21,
    CrdTitle: "Titulo de la Experiencia",
    CrdText: "Texto descriptovo de la Experiencia",
  },
  {
    ImgSrc: img26,
    CrdTitle: "Titulo de la Experiencia",
    CrdText: "Texto descriptovo de la Experiencia",
  },
];
