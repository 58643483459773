export const eventsData = [
  {
    titulo: "Conversaciones Individuales",
    titulo2: "Abordaje Interdisciplinario",
  },
  {
    titulo: "Talleres",
    titulo2: "Seminarios",
  },
  {
    titulo: "Programas",
    titulo2: "Capacitaciones",
  },
];
