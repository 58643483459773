import React from "react";
import EventCard from "./EventCard";
import { eventsData } from "./EventsData";
import "./NextEvents.css";

export default function NextEvents() {
  return (
    <div className="ne-container">
      <div
        id="carouselExampleDark"
        className="carousel carousel-dark slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          {eventsData.map((_, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : "false"}
              aria-label={`Slide ${index + 1}`}
              style={{ backgroundColor: "#D4AF37" }}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          {eventsData.map(({ titulo, titulo2 }, index) => (
            <div
              key={index}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
              data-bs-interval="5000"
            >
              <div className="d-flex flex-column align-items-center">
                <EventCard titulo={titulo} titulo2={titulo2} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
