import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <p>
        © 2024 Ricardo Chiani. Powered by{" "}
        <a
          href="https://gcarreiras.com.ar"
          target="_blank"
          rel="noopener noreferrer"
        >
          gcarreiras
        </a>
        .
      </p>
    </footer>
  );
};

export default Footer;
